import { Alert, BaseComponent, Button, Carousel, Collapse, Dropdown, Modal, Offcanvas, Popover, ScrollSpy, Tab, Toast, Tooltip } from "bootstrap";
import "../styles/index.scss";
import "@fortawesome/fontawesome-free";
import "./slick";
import "ekko-lightbox/dist/ekko-lightbox.js";

console.log("webpack starterkit");
// Loading page
var loaderPage = function () {
  $(".gtco-loader").fadeOut("slow");
};

// iPad and iPod detection
var isiPad = function () {
  return navigator.platform.indexOf("iPad") != -1;
};

var isiPhone = function () {
  return navigator.platform.indexOf("iPhone") != -1 || navigator.platform.indexOf("iPod") != -1;
};

// Parallax
var parallax = function () {
  $(window).stellar();
};

function getCopyYear() {
  var d = new Date();
  document.getElementById("copyYear").innerHTML = d.getFullYear();
}

$(document).ready(function () {
  // Slider
  $(".project-slider").slick({
    dots: false,
    infinite: false,
    arrows: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });
  $(".go-next").on("click", function () {
    $(".project-slider").slick("slickNext");
    setTimeout(function () {
      var getStatus = $(".project-slider .slick-next").attr("aria-disabled");
      if (getStatus == "true") {
        $(".go-next").addClass("disabled");
      } else {
        $(".go-next").removeClass("disabled");
      }
      $(".go-prev").removeClass("disabled");
    }, 500);
  });
  $(".go-prev").on("click", function () {
    $(".project-slider").slick("slickPrev");
    setTimeout(function () {
      var getStatus = $(".project-slider .slick-prev").attr("aria-disabled");
      if (getStatus == "true") {
        $(".go-prev").addClass("disabled");
      } else {
        $(".go-prev").removeClass("disabled");
      }
      $(".go-next").removeClass("disabled");
    }, 500);
  });
});

// $(document).ready(function(){
//   $('.customer-logos').slick({
//     slidesToShow: 6,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 1000,
//     arrows: false,
//     dots: false,
//     pauseOnHover: false,
//     responsive: [{
//       breakpoint: 768,
//       settings: {
//         slidesToShow: 4
//       }
//     }, {
//       breakpoint: 520,
//       settings: {
//         slidesToShow: 3
//       }
//     }]
//   });
// });

// Reflect scrolling in navigation

var navActive = function (section) {
  var $el = $("#navbar > ul");
  $el.find("li").removeClass("active");
  $el.each(function () {
    $(this)
      .find('a[data-nav-section="' + section + '"]')
      .closest("li")
      .addClass("active");
  });
};

// Document on load.
$(function () {
  // getCopyYear();
  loaderPage();
});

$(document).on("click", '[data-toggle="lightbox"]', function (event) {
  event.preventDefault();
  $(this).ekkoLightbox({
    alwaysShowClose: false,
    showArrows: false,
  });
});
